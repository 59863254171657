import { EventAggregator }             from 'aurelia-event-aggregator';
import { inject }                      from 'aurelia-framework';
import { I18N }                        from 'aurelia-i18n';
import { HighwaysRepository }          from 'modules/management/concessions/concessions-tree/highways/services/repository';
import { InterventionTypesRepository } from 'modules/management/concessions/concessions-tree/intervention-types/services/repository';
import { OEDatasRepository }           from 'modules/management/concessions/concessions-tree/lot-constructions/oe-data/services/repository';
import { LotInterventionsRepository }  from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { PkTypesRepository }           from 'modules/management/concessions/concessions-tree/pk-types/services/repository';
import { EntitiesRepository }          from 'modules/management/concessions/entities/services/repository';
import { TeamsRepository }             from 'modules/management/concessions/teams/services/repository';
import { FieldsRepository }            from 'modules/management/dynamic-fields/association-sectors/services/fields-repository';
import { LotConstructionType }         from 'modules/management/models/lot-construction-type';
import { ConcessionsRepository }       from 'modules/management/specifications/concessions/services/repository';
import { ListingRevisionsRepository }  from 'modules/management/specifications/listings/listing-revisions/services/repository';
import { ListingsRepository }          from 'modules/management/specifications/listings/services/repository';
import { BaseFilterFormSchema }        from 'resources/classes/base-filter-form-schema';
import { SessionStorage }              from 'resources/services/session-storage';

@inject(SessionStorage, EventAggregator, I18N, ConcessionsRepository, EntitiesRepository, TeamsRepository, HighwaysRepository, InterventionTypesRepository, FieldsRepository, ListingsRepository, ListingRevisionsRepository, LotInterventionsRepository, OEDatasRepository, PkTypesRepository)
export class ConstructionFilterFormSchema extends BaseFilterFormSchema {

    schemaSlug = 'construction_filters';

    constructor(storage, eventAggregator, i18n, oeDatasRepository, pkTypesRepository) {
        super(storage, eventAggregator, i18n);

        this.oeDatasRepository = oeDatasRepository;
        this.pkTypesRepository = pkTypesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    async schema(viewModel) {

        const branchLabel        = 'form.field.branch';
        const edgeLabel          = 'form.field.edge';
        const notApplicableLabel = 'form.text.not-applicable';

        const filterData = async (newValue) => {
            return await this.oeDatasRepository.filterData(newValue);
        };

        /**
         * Filter Lot Construction predominant attributes
         * from OELotConstructionData
         *
         * @param lotConstructionId
         * @returns {Promise<void>}
         */
        const filterConstructionPredominant = async (lotConstructionId) => {

            // Reset Edge
            this.edge_id.label = edgeLabel;
            this.edge_id.instance.hide(false);
            this.edge_id.instance.clear(!lotConstructionId);

            // Reset Branch
            this.branch_id.label = branchLabel;
            this.branch_id.instance.hide(false);
            this.branch_id.instance.clear(!lotConstructionId);

            // Enable all fields
            this.branch_id.instance.disable(!lotConstructionId);
            this.edge_id.instance.disable(!lotConstructionId);

            // Early bird gets the worm :)
            if (!lotConstructionId) return;

            // Get filtered data and predominant
            const { predominant, options } = await filterData(lotConstructionId);

            // Update form behaviour based on predominant attribute
            switch (predominant) {
                /* Branch is predominant */
                case 'branch':
                    // Set Branch options
                    this.branch_id.options = options;

                    // Ignore Edge
                    this.edge_id.label = notApplicableLabel;
                    this.edge_id.instance.hide();

                    break;

                /* Edge is predominant */
                case 'edge':
                    // Set Edge options
                    this.edge_id.options = options;

                    // Ignore Branch
                    this.branch_id.label = notApplicableLabel;
                    this.branch_id.instance.hide();

                    break;

                /* PK is predominant (options are always set) */
                case 'pk':

                    // Ignore Branch
                    this.branch_id.label = notApplicableLabel;
                    this.branch_id.instance.hide();

                    // Ignore Edge
                    this.edge_id.label = notApplicableLabel;
                    this.edge_id.instance.hide();

                    break;

                /* No predominance, meaning no valid OE data is available. */
                default:

                    // Ignore Branch
                    this.branch_id.label = notApplicableLabel;
                    this.branch_id.instance.disable();

                    // Ignore Edge
                    this.edge_id.label = notApplicableLabel;
                    this.edge_id.instance.disable();

                    break;
            }

        };

        /**
         * Update Data
         *
         * @param criteria
         * @returns {Promise<void>}
         */
        const updateOELotConstructionData = async (criteria = null) => {
            // Early bird gets the worm.
            if (!criteria) {
                viewModel.filterModel.o_e_lot_construction_data_id = null;
                return;
            }

            // Search PK values based on given criteria
            let oeData = await this.oeDatasRepository.searchPKValues(criteria);

            // if it has
            if (!oeData || !Array.isArray(oeData)) {
                viewModel.filterModel.o_e_lot_construction_data_id = null;
                return;
            }

            if (oeData.length === 1) {
                viewModel.filterModel.o_e_lot_construction_data_id = oeData[0].id;
            }
        };

        // If lot_construction_id is set, fetch construction data.
        let constructionResponse = false;

        if (viewModel.filterModel.lot_construction_id) {
            constructionResponse = await filterData(viewModel.filterModel.lot_construction_id);
        }

        const { predominant, options } = constructionResponse || {};

        this.lot_construction_id = {
            type:         'select2',
            key:          'lot_construction_id',
            label:        'form.field.local',
            required:     false,
            remoteSource: () => viewModel.filterModel.lot_intervention_id
                ? this.lotInterventionsRepository.allConstructions(viewModel.filterModel.lot_intervention_id, LotConstructionType.OE)
                : Promise.resolve([]),
            size:         4,
            observers:    [
                (newValue) => {
                    filterConstructionPredominant(newValue);
                },
            ],
        };

        this.branch_id = {
            type:         'select2',
            key:          'branch_id',
            required:     false,
            label:        constructionResponse && predominant !== 'branch' ? notApplicableLabel : branchLabel,
            remoteSource: () => Promise.resolve(
                (constructionResponse && predominant === 'branch')
                    ? options
                    : [],
            ),
            size:         4,
            attributes:   {
                disabled: !(constructionResponse && predominant === 'branch'),
            },
            observers:    [
                (newValue) => {
                    if (newValue)
                        updateOELotConstructionData({ branch_id: newValue });
                },
            ],
        };

        this.edge_id = {
            type:         'select2',
            key:          'edge_id',
            required:     false,
            label:        constructionResponse && predominant !== 'edge' ? notApplicableLabel : edgeLabel,
            remoteSource: () => Promise.resolve(
                (constructionResponse && predominant === 'edge')
                    ? options
                    : [],
            ),
            size:         4,
            attributes:   {
                disabled: !(constructionResponse && predominant === 'edge'),
            },
            observers:    [
                (newValue) => {
                    if (newValue)
                        updateOELotConstructionData({ edge_id: newValue });
                },
            ],
        };

        this.collection_pk = {
            type:     'field-group',
            label:    'form.field.collection-pk',
            key:      'collection_pk',
            required: false,
            size:     8,
            fields:   [
                {
                    type:       'divider',
                    label:      viewModel.appContainer.i18n.tr('form.field.from'),
                    size:       'pk-divider',
                    attributes: {
                        style: 'font-size: .9rem; margin: 0 0 0 .75rem; display: inline-block;',
                    },
                },
                {
                    type:            'text',
                    key:             'pk_s_from',
                    showLabel:       false,
                    required:        false,
                    size:            1,
                    idPrefix:        this.schemaSlug,
                    observers:       [
                        (newValue) => {
                            if (newValue)
                                newValue.length === 3 && this.focusField('pk_f_from')
                        },
                    ],
                    valueConverters: [
                        { name: 'numbersOnly' },
                    ],
                    attributes:      {
                        maxlength: 3,
                    },
                },
                {
                    type:  'divider',
                    label: '+',
                    size:  'pk-divider',
                },
                {
                    type:            'text',
                    key:             'pk_f_from',
                    showLabel:       false,
                    required:        false,
                    size:            1,
                    idPrefix:        this.schemaSlug,
                    observers:       [
                        (newValue) => {
                            if (newValue)
                                newValue.length === 6 && this.focusField('pk_s_to')
                        },
                    ],
                    valueConverters: [
                        { name: 'autoDecimalValue' },
                    ],
                    attributes:      {
                        maxlength: 6,
                    },
                },
                {
                    type:       'divider',
                    label:      viewModel.appContainer.i18n.tr('form.field.to'),
                    size:       'pk-divider',
                    attributes: {
                        style: 'font-size: .9rem; margin: 0 0 0 1.5rem; display: inline-block;',
                    },
                },
                {
                    type:            'text',
                    key:             'pk_s_to',
                    showLabel:       false,
                    required:        false,
                    size:            1,
                    idPrefix:        this.schemaSlug,
                    observers:       [
                        (newValue) => {
                            if (newValue)
                                newValue.length === 3 && this.focusField('pk_f_to')
                        },
                    ],
                    valueConverters: [
                        { name: 'numbersOnly' },
                    ],
                    attributes:      {
                        maxlength: 3,
                    },
                },
                {
                    type:  'divider',
                    label: '+',
                    size:  'pk-divider',
                },
                {
                    type:            'text',
                    key:             'pk_f_to',
                    showLabel:       false,
                    required:        false,
                    size:            1,
                    idPrefix:        this.schemaSlug,
                    valueConverters: [
                        { name: 'autoDecimalValue' },
                    ],
                    attributes:      {
                        maxlength: 6,
                    },
                },
            ],
        }

        this.pk_type_id = {
            type:         'select2',
            idPrefix:     this.schemaSlug,
            key:          'pk_type_id',
            label:        'form.field.pk-type',
            size:         4,
            remoteSource: () => this.pkTypesRepository.all(),
            observers:    [],
            required:     false,
        };
    }

    /**
     * Focus on a different field from current schema
     * @param fieldKey
     */
    focusField(fieldKey) {
        document.querySelector(`#${this.schemaSlug}__${fieldKey}`)?.focus();
    }
}
